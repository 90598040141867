import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import * as dat from 'lil-gui'
import { CompressedPixelFormat, DirectionalLight } from 'three'
import { Sky } from 'three/examples/jsm/objects/Sky.js';
import { Water } from 'three/examples/jsm/objects/Water.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass.js';
import { VignetteShader } from 'three/examples/jsm/shaders/VignetteShader.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js';
import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass.js';
import gsap from 'gsap'
import Stats from 'stats.js'

let sceneReady = false;
let scrolled_yet = false;

//For touchscreen
var xDown = null;                                                        
var yDown = null;

/**
 * Positions
 */
 var boat_position = {
    x: -480,
    z: -190,
    y: 40
}

var gorilla_position = {
    x: 5,
    z: 5,
    y: 10
}

var kingmeditate_position = {
    x: 478,
    z: -335,
    y: 5
}

/**
 * Fetch Opensea Information
 */
 //Gorilla

var gorilla_opensea_info;
fetchItem().then(data => {
    gorilla_opensea_info = data; // fetched
    console.log(gorilla_opensea_info)
  });
  

/**
 * Loaders
 */
 const loadingBarElement = document.querySelector('.loading-bar')
 const loadingText = document.querySelector('.loading-text');
 const scroll_image = document.querySelector('.scroll');
 const loadingManager = new THREE.LoadingManager(
     // Loaded
     () =>
     {
         // Wait a little
         window.setTimeout(() =>
         {
             // Animate overlay
             gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })
 
             // Update loadingBarElement
             loadingBarElement.classList.add('ended')
             loadingBarElement.style.transform = ''

             loadingText.classList.add('ended')
             loadingText.remove()
         }, 500)

         window.setTimeout(() =>
         {
             sceneReady = true
         }, 2000)
     },
 
     // Progress
     (itemUrl, itemsLoaded, itemsTotal) =>
     {
         // Calculate the progress and update the loadingBarElement
         const progressRatio = itemsLoaded / itemsTotal
         loadingBarElement.style.transform = `scaleX(${progressRatio})`
     }
 )

 const Text_loader = new FontLoader(loadingManager);
 Text_loader.load( 'fonts/helvetiker_regular.typeface.json', function ( font ) {

     const color1 = 0x4E2C00;
     const color2 = 0x44ACB4;
     const color3 = 0x418D43;

     const matLite = new THREE.MeshBasicMaterial( {
         color: color1,
         transparent: true,
         opacity: 0.6,
         side: THREE.DoubleSide
     } );

     const matLite2 = new THREE.MeshBasicMaterial( {
        color: color2,
        transparent: true,
        opacity: 0.6,
        side: THREE.DoubleSide
    } );

    const matLite3 = new THREE.MeshBasicMaterial( {
        color: color3,
        transparent: true,
        opacity: 0.6,
        side: THREE.DoubleSide
    } );

     const message = 'Futuristic Apes';
     const message2 = 'Destructive Navy';
     const message3 = 'King Meditate';

     const message1_description = 'The Ape is the king of the slither gang. \nHis ability to move smoothly and track his pray to death is underestimated. \nWelcome to the Futuristic Apes era'
     const message1_owner = 'Ta mere en shorts'

     const shapes = font.generateShapes( message, 15 );
     const shapes2 = font.generateShapes( message2, 15 );
     const shapes3 = font.generateShapes( message3, 15 );
     const shapes_1 = font.generateShapes( message1_description, 3 );
     const shapes_2 = font.generateShapes( message1_owner, 3 );

     const geometry1 = new THREE.ShapeGeometry( shapes );
     const geometry2 = new THREE.ShapeGeometry( shapes2 );
     const geometry3 = new THREE.ShapeGeometry( shapes3 );
     const geometry4 = new THREE.ShapeGeometry( shapes_1 );
     const geometry5 = new THREE.ShapeGeometry( shapes_2 );

     geometry1.computeBoundingBox();
     geometry2.computeBoundingBox();
     geometry3.computeBoundingBox();
     geometry4.computeBoundingBox();
     geometry5.computeBoundingBox();
     const xMid = - 0.5 * ( geometry1.boundingBox.max.x - geometry1.boundingBox.min.x );
     geometry1.translate( xMid, 0, 0 );
     const xMid2 = - 0.5 * ( geometry2.boundingBox.max.x - geometry2.boundingBox.min.x );
     geometry2.translate( xMid2, 0, 0 );
     const xMid3 = - 0.5 * ( geometry3.boundingBox.max.x - geometry3.boundingBox.min.x );
     geometry3.translate( xMid3, 0, 0 );
     const xMid4 = - 0.5 * ( geometry4.boundingBox.max.x - geometry4.boundingBox.min.x );
     geometry4.translate( xMid4, 0, 0 );
     const xMid5 = - 0.5 * ( geometry5.boundingBox.max.x - geometry5.boundingBox.min.x );
     geometry5.translate( xMid5, 0, 0 );

     // make shape ( N.B. edge view not visible )

     const text1 = new THREE.Mesh( geometry1, matLite );
     text1.position.z = -240;
     text1.position.y = 45
     text1.position.x = 20
     scene.add( text1 );

     const text1_subtext = new THREE.Mesh( geometry4, matLite );
     text1_subtext.position.z = -240;
     text1_subtext.position.y = 25
     text1_subtext.position.x = 20
     scene.add( text1_subtext );

     const text2 = new THREE.Mesh( geometry2, matLite2 );
     text2.position.x = boat_position.x - 150;
     text2.position.z = boat_position.z - 150;
     text2.position.y = boat_position.y + 20;
     text2.rotation.y = 1;
     scene.add( text2 );

     const text3 = new THREE.Mesh( geometry3, matLite3 );
     text3.position.x = kingmeditate_position.x;
     text3.position.z = kingmeditate_position.z;
     text3.position.y = kingmeditate_position.y + 10;
     scene.add( text3 );
 } );



// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
const mouse = new THREE.Vector2()



/**
 * Overlay
 */
 const overlayGeometry = new THREE.PlaneGeometry(30, 30, 1, 1)
 const overlayMaterial = new THREE.ShaderMaterial({
     // wireframe: true,
     transparent: true,
     uniforms:
     {
         uAlpha: { value: 1 }
     },
     vertexShader: `
         void main()
         {
             gl_Position = vec4(position, 1.0);
         }
     `,
     fragmentShader: `
         uniform float uAlpha;
 
         void main()
         {
             gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
         }
     `
 })
 const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
 scene.add(overlay)

 const dracoLoader = new DRACOLoader(loadingManager)
 dracoLoader.setDecoderPath('/draco/')
 
 const gltfLoader = new GLTFLoader(loadingManager)
 gltfLoader.setDRACOLoader(dracoLoader)

const stats = new Stats()
stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
//document.body.appendChild(stats.dom)


/**
 * Base
 */

// Debug
const gui = new dat.GUI(loadingManager)

//Detect if mobile
const mobile = ( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
);

if(mobile){
    scrolled_yet = true; //There is no scroll on mobile, we do not want to show the scrolling gif
    scroll_image.remove();
    console.log("Mobile")

}else{
    console.log("Web Browser")
}

var boat, boatcannon1, boatcannon2, boatcannon3, boatcannon4, boat_hull, window_side_left, Text, Text001, Antenna, boat_tower001, Boat_base;
var gorilla, kingmeditate, palmier;
var Icosphere, Face, Leftfoot, Leftarm, Leftarm001, Rightfoot, Eyes;
let sky, sun;
let water;

// Add Sky
sky = new Sky();
sky.scale.setScalar( 450000 );
scene.add( sky );
sun = new THREE.Vector3();

/**
 * Points of interest
 */

 const raycaster = new THREE.Raycaster()
 const points = [
     {
         position: new THREE.Vector3(gorilla_position.x+10, gorilla_position.y, gorilla_position.z+10),
         element: document.querySelector('.point-0')
     },
     {
         position: new THREE.Vector3(boat_position.x, boat_position.y, boat_position.z),
         element: document.querySelector('.point-1')
     },
     {
         position: new THREE.Vector3(kingmeditate_position.x , kingmeditate_position.y, kingmeditate_position.z-10),
         element: document.querySelector('.point-2')
     }
 ]

/**
 * Composer
 */
 let composer;

/**
 * Textures
 */
 var textureLoader = new THREE.TextureLoader(loadingManager);
 var texture_boat = textureLoader.load( 'textures/Hull.jpg' );
 var texture_base_boat = textureLoader.load( 'textures/Base.jpg' );
 var texture_windows = textureLoader.load( 'textures/windows.jpg' );
 var texture_cannon_middleaft = textureLoader.load( 'textures/windows.jpg' );

 var texture_gorilla = textureLoader.load( 'textures/gorilla.jpg' );

 var texture_kingmeditate = textureLoader.load( 'textures/texture_kingmeditate.jpg' );

 var texture_palmier = textureLoader.load( 'textures/texture_palmier_test2.jpg' );


 texture_boat.flipY = false;
 texture_base_boat.flipY = false;
 texture_windows.flipY = false;
 texture_cannon_middleaft.flipY = false;
 texture_gorilla.flipY = false;
 texture_kingmeditate.flipY = false;
 texture_palmier.flipY = false;

 texture_boat.encoding = THREE.sRGBEncoding;
 texture_base_boat.encoding = THREE.sRGBEncoding;
 texture_windows.encoding = THREE.sRGBEncoding;
 texture_cannon_middleaft.encoding = THREE.sRGBEncoding;
 texture_gorilla.encoding = THREE.sRGBEncoding;
 texture_kingmeditate.encoding = THREE.sRGBEncoding;
 texture_palmier.encoding = THREE.sRGBEncoding;

 const baked_palmier = new THREE.MeshBasicMaterial({ map: texture_palmier })
 const baked_kingmeditate = new THREE.MeshBasicMaterial({ map: texture_kingmeditate })


// Water
const waterGeometry = new THREE.PlaneGeometry( 5000, 5000 );

water = new Water(
    waterGeometry,
    {
        textureWidth: 512,
        textureHeight: 512,
        waterNormals: new THREE.TextureLoader(loadingManager).load( 'textures/waternormals.jpg', function ( texture ) {

            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

        } ),
        sunDirection: new THREE.Vector3(),
        sunColor: 0xFFE29B,
        waterColor: 0x0B5394,
        distortionScale: 1,
        fog: scene.fog !== undefined
    }
);

water.rotation.x = - Math.PI / 2;
scene.add( water );

let mixer = null

/**
 * Models
 */
gltfLoader.load(
    '/models/gorilla6.glb',
    (gltf) =>
    {
        gorilla = gltf.scene;
        gorilla.traverse ( ( o ) => {
            if ( o.isMesh ) {
              // note: for a multi-material mesh, `o.material` may be an array,
              // in which case you'd need to set `.map` on each value.

                if(o.material.name == "Material.005"){
                    o.material.map = texture_gorilla;
                }

                if(o.material.name == "Material.002"){
                    o.material.map = texture_gorilla;
                }

                if(o.material.name == "Eyes_bg"){
                    o.material.map = texture_gorilla;
                }

                //o.material.map = texture_gorilla;          
            }
          } );

            Icosphere = gorilla.children.find((child) => child.name === 'Icosphere')
            Face = gorilla.children.find((child) => child.name === 'Face')
            Leftfoot = gorilla.children.find((child) => child.name === 'Leftfoot')
            Leftarm = gorilla.children.find((child) => child.name === 'Leftarm')
            Leftarm001 = gorilla.children.find((child) => child.name === 'Leftarm001')
            Rightfoot = gorilla.children.find((child) => child.name === 'Rightfoot')
            Eyes = Face.children.find((child) => child.name === 'Icosphere_2')
            //Face.position.x = Face.position.x -0.4 //Adjustement for face position on body
            //Icosphere.position.y = Icosphere.position.y + 4

        scene.add(gorilla)
    }
)



gltfLoader.load(
    '/models/palmier2.glb',
    (gltf) =>
    {
        palmier = gltf.scene;
        palmier.traverse ( ( o ) => {
            if ( o.isMesh ) {
              // note: for a multi-material mesh, `o.material` may be an array,
              // in which case you'd need to set `.map` on each value.
              o.material = baked_palmier;
            }
          } );


        scene.add(palmier)
    }
)

gltfLoader.load(
    '/models/not_a_warship.glb',
    (gltf) =>
    {
        boat = gltf.scene;
        boat.traverse ( ( o ) => {
            if ( o.isMesh ) {
              // note: for a multi-material mesh, `o.material` may be an array,
              // in which case you'd need to set `.map` on each value.
              if(o.material.name == "boat"){
                o.material.map = texture_boat;
              }

              if(o.material.name == "boat_floor"){
                o.material.map = texture_base_boat;
              }

              if(o.material.name == "boat_windows"){
                o.material.map = texture_windows;
              }              
            }
          } );
        
        //Cannons
        boatcannon1 = boat.children.find((child) => child.name === 'Cannon_front')
        boatcannon2 = boat.children.find((child) => child.name === 'Cannon_middle')
        boatcannon3 = boat.children.find((child) => child.name === 'Cannon_middle_aft')
        boatcannon4 = boat.children.find((child) => child.name === 'Cannon_aft')
        boat_hull = boat.children.find((child) => child.name === 'boat_hull')
        window_side_left = boat.children.find((child) => child.name === 'window_side_left')
        Text = boat.children.find((child) => child.name === 'Text')
        Text001 = boat.children.find((child) => child.name === 'Text001')
        Antenna = boat.children.find((child) => child.name === 'Antenna')
        boat_tower001 = boat.children.find((child) => child.name === 'boat_tower001')
        Boat_base = boat.children.find((child) => child.name === 'Boat_base')

        scene.add(boat)
    }
)

gltfLoader.load(
    '/models/king_meditate.glb',
    (gltf) =>
    {
        kingmeditate = gltf.scene;
        kingmeditate.traverse ( ( o ) => {
            if ( o.isMesh ) {
              // note: for a multi-material mesh, `o.material` may be an array,
              // in which case you'd need to set `.map` on each value.
              o.material = baked_kingmeditate;
            }
          } );
        //scene.add(kingmeditate)
    }
)

//const geometry = new THREE.BoxGeometry( 30, 30, 30 );
//const material = new THREE.MeshStandardMaterial( { roughness: 0 } );

//mesh = new THREE.Mesh( geometry, material );
//scene.add( mesh );


/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xD98C6F, 100)
//scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0x292929, 70)
const dirLightHelper = new THREE.DirectionalLightHelper( directionalLight, 1 );
//scene.add( dirLightHelper );

directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(100, 150, 120)
directionalLight.rotation.set(0.8, 0, 0)
scene.add(directionalLight)
const cameraHelper = new THREE.CameraHelper(directionalLight.shadow.camera)
scene.add(cameraHelper)


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}



/**
 * Events
 */

/**
 * Scroll
 */

const mouse_moved = document.getElementById('webgl_id');
mouse_moved.addEventListener('mousemove', onMouseMove);
window.addEventListener('touchstart', handleTouchStart, false);        
window.addEventListener('touchmove', handleTouchMove, false);

window.addEventListener('click', () =>
{
    if(currentIntersect)
    {
        switch(currentIntersect.object)
        {
            case text1:
                console.log('click on gorilla')
                break

            case text2:
                console.log('click on boat')
                break

            case text3:
                console.log('click on kingmeditate')
                break
        }
    }
})

window.addEventListener( 'wheel', onMouseWheel );
//window.addEventListener( 'mousemove', console.log("mousemove") );

window.addEventListener( 'resize' , () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    composer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    composer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})



/**
 * Camera
 */

const cameraGroup = new THREE.Group(loadingManager)
scene.add(cameraGroup)




// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 5, 800)
camera.position.set(5, 35, 5)
camera.rotateY(Math.PI/6)
gsap.to(camera.position, { duration: 3, delay: 0, y: +10 })
//camera.position.set(10, 10, 10)
//gsap.to(camera.position, { duration: 3, delay: 1, y: 0 })
cameraGroup.add(camera)
//scene.add(camera)


// Controls
//const controls = new OrbitControls(camera, canvas)
//controls.target.set(0, 1, 0)
//controls.enableDamping = true

/**
 * Renderer
 */
 const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialiasing: true
})


// postprocessing

const renderModel = new RenderPass( scene, camera );

const effectFilm = new FilmPass( 0.1, 0.1, 2048, false );

const shaderVignette = VignetteShader;

const effectVignette = new ShaderPass( shaderVignette );

const smaaPass = new SMAAPass();
smaaPass.enabled = true;

const afterimagePass = new AfterimagePass(0.8);
afterimagePass.enabled = false;



effectVignette.uniforms[ "offset" ].value = 0.85;
effectVignette.uniforms[ "darkness" ].value = 1.6;


renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.outputEncoding = THREE.sRGBEncoding

composer = new EffectComposer( renderer );

composer.addPass( renderModel );
composer.addPass( effectFilm );
composer.addPass( effectVignette);
composer.addPass( afterimagePass );
composer.addPass( smaaPass);

/**
 * For Sun
 */
const effectController = {
    turbidity: 10,
    rayleigh: 3,
    mieCoefficient: 0.005,
    mieDirectionalG: 0.7,
    elevation: 0.5,
    azimuth: 180,
    exposure: renderer.toneMappingExposure
};

const phi = THREE.MathUtils.degToRad( 90 - effectController.elevation );
const theta = THREE.MathUtils.degToRad( effectController.azimuth );
sun.setFromSphericalCoords( 1, phi, theta );


/**
 * gui
 */
 gui.close() // By default we want the gui closed
 gui.add( effectController, 'turbidity', 0.0, 20.0, 0.1 ).onChange( guiChanged );
 gui.add( effectController, 'rayleigh', 0.0, 4, 0.001 ).onChange( guiChanged );
 gui.add( effectController, 'mieCoefficient', 0.0, 0.1, 0.001 ).onChange( guiChanged );
 gui.add( effectController, 'mieDirectionalG', 0.0, 1, 0.001 ).onChange( guiChanged );
 gui.add( effectController, 'elevation', 0, 90, 0.1 ).onChange( guiChanged );
 gui.add( effectController, 'azimuth', - 180, 180, 0.1 ).onChange( guiChanged );
 gui.add( effectController, 'exposure', 0, 1, 0.0001 ).onChange( guiChanged );

 const waterUniforms = water.material.uniforms;
 const folderWater = gui.addFolder( 'Water' );
 folderWater.add( waterUniforms.distortionScale, 'value', 0, 8, 0.1 ).name( 'distortionScale' );
 folderWater.add( waterUniforms.size, 'value', 0.1, 10, 0.1 ).name( 'size' );
 folderWater.open();

 guiChanged();


/**
 * Animate
 */

const clock = new THREE.Clock()
let previousTime = 0


const tick = () =>
{
    stats.begin()
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
    const time = performance.now() * 0.001;
    //console.log(time)

    if(sceneReady)
    {
        // Go through each point
        for(const point of points)
        {
            // Get 2D screen position
            const screenPosition = point.position.clone()
            screenPosition.project(camera)
    
            // Set the raycaster
            raycaster.setFromCamera(screenPosition, camera)
            const intersects = raycaster.intersectObjects(scene.children, true)
    
            // No intersect found
            if(intersects.length === 0)
            {
                // Show
                point.element.classList.add('visible')
            }

            // Intersect found
            else
            {
                // Get the distance of the intersection and the distance of the point
                const intersectionDistance = intersects[0].distance
                const pointDistance = point.position.distanceTo(camera.position)
    
                // Intersection is close than the point
                if(intersectionDistance < pointDistance)
                {
                    // Hide
                    point.element.classList.remove('visible')
                }
                // Intersection is further than the point
                else
                {
                    // Show
                    point.element.classList.add('visible')
                }
            }
    
            const translateX = screenPosition.x * sizes.width * 0.5
            const translateY = - screenPosition.y * sizes.height * 0.5
            point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
        }
    }


    if(mixer)
    {
        mixer.update(deltaTime)
    }

    //camera.position.x = Math.sin( .5 * Math.PI * ( mouse[ 0 ] - .5 ) );
    //camera.position.y = Math.sin( .25 * Math.PI * ( mouse[ 1 ] - .5 ) );
    //camera.position.z = Math.cos( .5 * Math.PI * ( mouse[ 0 ] - .5 ) );


    animateCannons(Math.sin( elapsedTime ), elapsedTime)
    animateFloatingBoat(Math.sin( elapsedTime * 0.75 )+ 20)
    animateKing(Math.sin( elapsedTime * 0.75 ))
    animateGorilla(Math.sin( elapsedTime ), elapsedTime)


    //Raycaster
    //raycaster.setFromCamera(mouse, camera);



    // Update controls
    //controls.update()

    //console.log( camera.position.x, camera.position.y, camera.position.z )

    water.material.uniforms[ 'time' ].value += 1.0 / 1400.0;

    // Render
    //renderer.render( scene, camera )

    composer.render( scene, camera )

    // Call tick again on the next frame
    window.requestAnimationFrame( tick )
    stats.end()
}

function guiChanged() {

    const uniforms = sky.material.uniforms;
    uniforms[ 'turbidity' ].value = effectController.turbidity;
    uniforms[ 'rayleigh' ].value = effectController.rayleigh;
    uniforms[ 'mieCoefficient' ].value = effectController.mieCoefficient;
    uniforms[ 'mieDirectionalG' ].value = effectController.mieDirectionalG;

    const phi = THREE.MathUtils.degToRad( 90 - effectController.elevation );
    const theta = THREE.MathUtils.degToRad( effectController.azimuth );

    sun.setFromSphericalCoords( 1, phi, theta );

    uniforms[ 'sunPosition' ].value.copy( sun );

    renderer.toneMappingExposure = effectController.exposure;
    renderer.render( scene, camera );

}

async function animateCannons(time, elapsed) {
    if(boatcannon1 && boatcannon2 && boatcannon3 && boatcannon4){
    gsap.to(boatcannon1.rotation, { duration: 8, delay: 0, z: time })
    gsap.to(boatcannon2.rotation, { duration: 10, delay: 0, y: time })
    gsap.to(boatcannon3.rotation, { duration: 10, delay: 0, y: time })
    gsap.to(boatcannon4.rotation, { duration: 10, delay: 2, y: time })
    }
}

async function animateFloatingBoat(time) {
    var duration = 1;
    if(boat_hull && window_side_left && Text && Text001 && Antenna && boat_tower001 && Boat_base && boatcannon1 && boatcannon2 && boatcannon3 && boatcannon4){
    gsap.to(boat_hull.position, { duration: duration, delay: 0, y: time })
    gsap.to(Boat_base.position, { duration: duration, delay: 0, y: time-5 })
    gsap.to(window_side_left.position, { duration: duration, delay: 0, y: time+18 })
    gsap.to(Text.position, { duration: duration, delay: 0, y: time+8 })
    gsap.to(Text001.position, { duration: duration, delay: 0, y: time-12 })
    gsap.to(Antenna.position, { duration: duration, delay: 0, y: time+24 })
    gsap.to(boat_tower001.position, { duration: duration, delay: 0, y: time })

    gsap.to(boatcannon1.position, { duration: duration, delay: 0, y: time+3 })
    gsap.to(boatcannon2.position, { duration: duration, delay: 0, y: time+3.5 })
    gsap.to(boatcannon3.position, { duration: duration, delay: 0, y: time+2 })
    gsap.to(boatcannon4.position, { duration: duration, delay: 0, y: time+4 })
    }
} 


async function animateKing(time) {
    if(kingmeditate){
    gsap.to(kingmeditate.position, { duration: 12, delay: 0, z: time/2, x: time/2, y:time/1.75 })
    }
}

async function animateGorilla(time, elapsed) {
    if(Icosphere && Face && Leftfoot && Leftarm && Leftarm001 && Rightfoot && Eyes){

        //gsap.to(Face.children[0].position, { duration: 2, delay: 0, y: -0.35 })
    if (Math.floor(elapsed) % 10 == 0){
        await gsap.to(Eyes.material.color, { r: 255, g: 0, b: 0 })
        await gsap.to(Eyes.material.emissive, { r: 255, g: 0, b: 0 })
        await gsap.to(Face.rotation, { duration: 1.2, delay: 0, y: -0.35 })
        gsap.to(Face.rotation, { duration: 0.5, delay: 2, y: -0.25, x:0.25 })
        gsap.to(Face.rotation, { duration: 1, delay: 4, y: 0.1, x:-0.25 })
        await gsap.to(Face.rotation, { duration: 0.5, delay: 6, y: 0.5 })
        gsap.to(Eyes.material.color, { r: 255, g: 255, b: 255 })
        gsap.to(Eyes.material.emissive, { r: 255, g: 255, b: 255 })
        
    }

    if (Math.floor(elapsed) % 3 == 0){
        await breatheGorilla(Icosphere)
    }


    //Causing framerate drop!!!
/*     gsap.to(Leftarm001.position, { duration: 2, delay: 0, y:time/10+2 })
    gsap.to(Leftarm.position, { duration: 2, delay: 0, y:time/10+2 })        

    gsap.to(Face.rotation, { duration: 30, delay: 0, z:time/8 })
    gsap.to(Icosphere.scale, { duration: 25, delay: 2, z:time+1.5 })
    gsap.to(Icosphere.rotation, { duration: 30, delay: 0, y:time/4, z:time/8 })
    gsap.to(Leftarm.rotation, { duration: 20, delay: 0, y:time/10 })
    gsap.to(Leftarm001.rotation, { duration: 20, delay: 0, y:time/10 })
    gsap.to(Leftfoot.rotation, { duration: 30, delay: 0, y:time/8 })
    gsap.to(Rightfoot.rotation, { duration: 30, delay: 0, y:time/8 }) */
    }
}

async function breatheGorilla(body){
    await gsap.to(body.scale, { duration: 1.6, delay: 0, z:1.55 })
    await gsap.to(body.scale, { duration: 1.1, delay: 0, z:1.45 })
}

function onMouseWheel( ev ) {

    scrolled_yet = true;
    scroll_image.remove(); //User figured out how to scrolled, we remove the scroll image

    const amount = ev.deltaY;
    if ( amount === 0 ) return;
    const dir = amount / Math.abs( amount );

    movePosition(dir);

}

function onMouseMove( ev ) {
    mouse.x = ev.clientX / sizes.width * 2 - 1
    mouse.y = - (ev.clientY / sizes.height) * 2 + 1
    console.log("mouse moving")
}

async function fetchItem(id, id2) {
    try {
        const options = {method: 'GET'};
        const response = await fetch('https://api.opensea.io/api/v1/collection/futuristicapes', options)
        const item = await response.json();
        return item;
    } catch (error) {
        console.error(error);
    }
}

//Mobile controls
function getTouches(evt) {
    return evt.touches ||             // browser API
           evt.originalEvent.touches; // jQuery
  }                                                     
                                                                           
  function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0];                                      
      xDown = firstTouch.clientX;                                      
      yDown = firstTouch.clientY;                                      
  };                                                
                                                                           
  function handleTouchMove(evt) {
      if ( ! xDown || ! yDown ) {
          return;
      }
  
      var xUp = evt.touches[0].clientX;                                    
      var yUp = evt.touches[0].clientY;
  
      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;
                                                                           
      if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
          if ( xDiff > 0 ) {
              /* right swipe */ 
          } else {
              /* left swipe */
          }                       
      } else {
          if ( yDiff > 0 ) {
              /* down swipe */ 
              movePosition(1)
          } else { 
              /* up swipe */
              movePosition(-1)
          }                                                                 
      }
      /* reset values */
      xDown = null;
      yDown = null;                                             
  };

  function movePosition(dir){
    //Zooming In
    //Boat to Kingmeditate
    if (camera.position.x == boat_position.x && camera.position.y == boat_position.y && dir == -1){
        gsap.to(camera.position, { duration: 1, delay: 0, x: kingmeditate_position.x, z: kingmeditate_position.z, y: kingmeditate_position.y})
        water.material.uniforms.distortionScale.value = 0.3
        water.material.uniforms.size.value = 2
    }
    //Gorilla to boat
    else if (camera.position.x == gorilla_position.x && camera.position.y == gorilla_position.y && dir == -1){
        gsap.to(camera.position, { duration: 1, delay: 0, x: boat_position.x, z: boat_position.z, y: boat_position.y})
        gsap.to(camera.rotation, { duration: 1, delay: 0, y: Math.PI / 4 })
        water.material.uniforms.distortionScale.value = 0.3
        water.material.uniforms.size.value = 0.1
    }
    //KingMeditate to gorilla
    else if (camera.position.x == kingmeditate_position.x && camera.position.y == kingmeditate_position.y && dir == -1){
        gsap.to(camera.position, { duration: 1, delay: 0, x: gorilla_position.x, z: gorilla_position.z, y: gorilla_position.y})
        gsap.to(camera.rotation, { duration: 1, delay: 0, y: Math.PI / 4 })
        water.material.uniforms.distortionScale.value = 0.3
        water.material.uniforms.size.value = 1.2
    }

    // Zooming Out
    //Boat to gorilla
    else if (camera.position.x == boat_position.x && camera.position.y == boat_position.y && dir == 1){
        gsap.to(camera.position, { duration: 1, delay: 0, x: gorilla_position.x, z: gorilla_position.z, y: gorilla_position.y})
        gsap.to(camera.rotation, { duration: 1, delay: 0, y: Math.PI / 4 })
        water.material.uniforms.distortionScale.value = 0.3
        water.material.uniforms.size.value = 1.2
    }
    //Gorilla to KingMeditate
    else if (camera.position.x == gorilla_position.x && camera.position.y == gorilla_position.y && dir == 1){
        gsap.to(camera.position, { duration: 1, delay: 0, x: kingmeditate_position.x, z: kingmeditate_position.z, y: kingmeditate_position.y})
        gsap.to(camera.rotation, { duration: 1, delay: 0, y: Math.PI / 4 })
        water.material.uniforms.distortionScale.value = 0.3
        water.material.uniforms.size.value = 0.1
    }
    //KingMeditate to boat
    else if (camera.position.x == kingmeditate_position.x && camera.position.y == kingmeditate_position.y && dir == 1){
        gsap.to(camera.position, { duration: 1, delay: 0, x: boat_position.x, z: boat_position.z, y: boat_position.y})
        water.material.uniforms.distortionScale.value = 0.3
        water.material.uniforms.size.value = 0.1
    }
  }


tick()